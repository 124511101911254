import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, Modal, Card, CardBody, } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

import moment from 'moment';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import i18next from 'i18next';

const Collaborator = props => {
  //meta title
  document.title = i18next.t('Dashboard PG document title');
	const location = useLocation();
  const { getRequest, postRequest } = useRequests();


  const [AlertData, setAlertData] = React.useState({
		variant: '',
		message: '',
	});
	const resetAlertData = () => {
		setTimeout(() => {
			setAlertData((AlertData) => ({
				...AlertData,
				variant: '',
				message: '',
			}));
		}, 5000);
	};


  const [ ReferralLink, setReferralLink ] = React.useState('');
  const handleLoadGetUserInfo = async () => {
    const res = await getRequest('get-user-info');
    if (res && res.code === 200) {
      let originalText = res.data;
      
      setReferralLink(process.env.REACT_APP_SITE_URL+'register/collaborator/'+originalText.collaborator_referral_code);
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  const [TransactionTBody, setTransactionTBody] = React.useState('');
  const handleT1Body = async (tempData) => {
    let tempTBody = tempData.map((element, index) => (
      <tr key={index}>
        <td>{element.u_id}</td>
          <td>{element.first_name}</td>
          <td>{element.last_name}</td>
          <td>{element.email}</td>
          <td>{element.country}</td>
          <td>+{element.country_code} {element.phone.slice(0, 6)}****&nbsp;
          </td>
          <td className="text-center">
            <div className="centerLink">
            <Link title="Transactions" to={"/collaboratoruserstransaction/" + btoa(element.u_id)} className="btn-sm btn btn-primary"><i className="bx bx-list-ul"></i></Link>
            </div>
          </td>
      </tr>
    ));

    setTransactionTBody(tempTBody);
  }

  const [ collaboratorsUsersCount, setCollaboratorsUsersCount ] = React.useState('');
  const handleLoadGetCollaboratorUsers = async () => {
    const res = await getRequest('get-collaborator-users');
    if (res && res.code === 200) {
      handleT1Body(res.data);
      setCollaboratorsUsersCount(res.data.length)
    }
    else {
      let tempTBody = (<tr>
                        <td colSpan="7" className="text-center">{i18next.t('No records found')}</td>
                      </tr>);

      setTransactionTBody(tempTBody);
    }
  }

  const handleReferralLinkCopied = () => {
    setAlertData({
      variant: 'success',
      message: i18next.t('Link copied!'),
    });
    resetAlertData();
  };



  
  React.useEffect(() => {
    handleLoadGetUserInfo();
    handleLoadGetCollaboratorUsers();

    if (typeof location.state !== 'undefined' && typeof location.state.message !== 'undefined') {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: location.state.status,
        message: location.state.message,
      }));
      resetAlertData();
		}
  }, []);


  return (
    <React.Fragment>
			{AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={i18next.t('PG title Tokenizedgreen')} breadcrumbItem={i18next.t('Collaborator')} />
          <Row>
            <Col xs="12">
              <div className="sidebar">
                <ul>
                  <li><Link to="/dashboard"><i className="bx bx-home-circle"></i> {i18next.t('Menu Dashboard')}</Link></li>
                  {
                    localStorage.getItem("Collaborator")=="true"?
                    <li><Link to="/collaborator" className="active"><i className="fas fa-handshake"></i> {i18next.t('Collaborator')}</Link></li>
                    :''
                  }    
                  <li><Link to="/wallet"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu My Wallet')}</Link></li>
                  <li><Link to="/properties"><i className="bx bx-shopping-bag"></i> {i18next.t('Menu Browse Property')}</Link></li>
                  <li><Link to="/portfolio"><i className="bx bx-cart-alt"></i> {i18next.t('Menu My Portfolio')}</Link></li>
                  <li><Link to="/claimed-fund"><i className="bx bx-money"></i> {i18next.t('Menu My Claimed Fund')}</Link></li>
                  <li><Link to="/mytransaction"><i className="bx bx-list-ul"></i> {i18next.t('Menu My Transaction')}</Link></li>
                  <li><Link to="/Kyc"><i className="bx bx-user-check"></i> {i18next.t('Menu KYC Verification')}</Link></li>
                  <li><Link to="/twofaauthenticator"><i className="bx bx-key"></i> {i18next.t('Menu 2FA Authenticator')}</Link></li>
                  <li><Link to="/dividends"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu Dividends')}</Link></li>
                </ul>
              </div>
              <div className="contentt">
                <Row>
                  <Col xs="12">
                    <Row>
                      <Col xl="12" md="12">
                        <div className="card">
                          <div className="card-body">
                            <div className="mb-3 h4 card-title">{i18next.t('Collaborator Referral Link')}</div>
                            <div className="Referal">
                              <p>{ReferralLink}</p>
                              <CopyToClipboard text={ReferralLink} onCopy={handleReferralLinkCopied}>
                                <i className="bx bx-copy-alt"></i>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                      <Col lg="3" md="6">
                          <Card>
                          <CardBody className="pb-3">
                              <Link to="#" className="d-flex flex-wrap">
                              <div>
                                  <h5 className="card-title mb-3 me-2">{i18next.t("Users")}</h5>
                                  <h4 className="mb-0">{collaboratorsUsersCount}</h4>
                              </div>
                              <div className="ms-auto align-self-end">
                                  <i className="bx bx-user display-4 text-light"></i>
                              </div>
                              </Link>
                          </CardBody>
                          </Card>
                      </Col>
                  </Col>
                  </Row>


                <Row>
                  <Col lg="12">
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-3 h4 card-title">{i18next.t('Users List')}</div>
                        <div className="table-responsive">
                          <table className="table mb-0" style={{ minWidth: '1200px' }}>
                            <thead className="table-light">
                              <tr>
                                <th>{i18next.t('#')}</th>
                                <th>{i18next.t('First Name')}</th>
                                <th>{i18next.t('Last Name')}</th>
                                <th>{i18next.t('Email ID')}</th>
                                <th>{i18next.t('Country Name')}</th>
                                <th>{i18next.t('Mobile No.')}</th>
                                <th className="text-center">{i18next.t('Action')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {TransactionTBody}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};


export default withTranslation()(Collaborator);
