import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

//actions
import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction,
  editLoginUserData
} from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';

//components
import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";
import RightSidebar from "../CommonForBoth/RightSidebar";

import axios from 'axios';
import CryptoJS from 'crypto-js';
import useDecryptedAuth from "../../Utils/useDecryptedAuth";


const Layout = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const decryptedAuth = useDecryptedAuth();
  // const {
  //   topbarTheme, layoutWidth, isPreloader, showRightSidebar
  // } = useSelector(state => ({
  //   topbarTheme: state.Layout.topbarTheme,
  //   layoutWidth: state.Layout.layoutWidth,
  //   isPreloader: state.Layout.isPreloader,
  //   showRightSidebar: state.Layout.showRightSidebar,
  // }));
  const selectLayoutData = createSelector(
    state => state.Layout.topbarTheme,
    state => state.Layout.layoutWidth,
    state => state.Layout.isPreloader,
    state => state.Layout.showRightSidebar,
    (topbarTheme, layoutWidth, isPreloader, showRightSidebar) => ({
      topbarTheme,
      layoutWidth,
      isPreloader,
      showRightSidebar
    })
  );
  const { topbarTheme, layoutWidth, isPreloader, showRightSidebar } = useSelector(selectLayoutData);

  /*
  document title
  */
  useEffect(() => {
    const title = props.location.pathname;
    let currentage = title.charAt(1).toUpperCase() + title.slice(2);

    document.title =
      currentage + "";
  }, [props.location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  //hides right sidebar on body click
  const hideRightbar = (event) => {
    var rightbar = document.getElementById("right-bar");
    //if clicked in inside right bar, then do nothing
    if (rightbar && rightbar.contains(event.target)) {
      return;
    } else {
      //if clicked in outside of rightbar then fire action for hide rightbar
      dispatch(showRightSidebarAction(false));
    }
  };

  /*
  layout settings
  */
  useEffect(() => {
    dispatch(changeLayout("horizontal"));
  }, [dispatch]);

  useEffect(() => {
    //init body click event fot toggle rightbar
    document.body.addEventListener("click", hideRightbar, true);

    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }, [isPreloader]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [dispatch, topbarTheme]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [dispatch, layoutWidth]);

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  
  const handleLoadVerifyAuth = async () => {
    const jsonpar = JSON.stringify({
      refreshtoken: decryptedAuth.refreshtoken,
    });
    const headers = {
      'Content-Type': 'application/json',
      'bcbqtoken': process.env.REACT_APP_TOKEN_SECRET,
      'accesstoken': 'Bearer ' + decryptedAuth.accesstoken,
      'lang': localStorage.getItem("I18N_LANGUAGE"),
    }
    axios.post(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + 'verify-auth', 
    {
      data: CryptoJS.AES.encrypt(jsonpar, process.env.REACT_APP_USER_TOKEN_SECRET).toString(),
    }, 
    {
      headers: headers
    })
    .then(async function (res) {
      res = res.data;
      
      if (res.code === 200) {
        if(typeof res.data !== 'undefined') {
          let bytes = CryptoJS.AES.decrypt(res.data, process.env.REACT_APP_USER_TOKEN_SECRET);
          let originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

          const authUser = {
            ...decryptedAuth,
            accesstoken: originalText.accesstoken
          }
          const cipherText = CryptoJS.AES.encrypt(JSON.stringify(authUser), process.env.REACT_APP_USER_TOKEN_SECRET).toString();
          localStorage.setItem("authUser", cipherText);

          // const updatedUserData = {
          //   ...userData,
          //   accesstoken: originalText.accesstoken
          // };
          // dispatch(editLoginUserData(updatedUserData));
        }
      } else {
        history.push('/logout', { code: 401, status: 'error', message: res.message });
      }
    })
    .catch(function (error) {
      console.error("Error : ", error);
    });
  }
  React.useEffect(() => {
    handleLoadVerifyAuth();
  }, []);


  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header
          theme={topbarTheme}
          isMenuOpened={isMenuOpened}
          openLeftMenuCallBack={openMenu}
        />
        <Navbar menuOpen={isMenuOpened} />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>

      {showRightSidebar ? <RightSidebar /> : null}
    </React.Fragment>
  );
};

Layout.propTypes = {
  changeLayout: PropTypes.func,/*  */
  changeLayoutWidth: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any
};

export default withRouter(Layout);