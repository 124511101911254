import PropTypes from 'prop-types'
import React, { useState } from "react"
import { Row, Col } from "reactstrap"
import ReactApexChart from "react-apexcharts"

const USDT = props => {
  const [menu, setMenu] = useState(false)
  const [currency, setcurrency] = useState("EURO")

  return (
    <React.Fragment>
      {props.reports.map((report, key) => (
        <Col md="12" key={key}>
          <div className='custom-card'>
            <div className='custom-cardbody'>
              <Row>
                <Col sm="6" xs='12'>
                  <div>
                    <p className="h4 card-title" style={{ fontWeight: '700', marginBottom: '12px' }}>Balance</p>
                    <h3 style={{ color: "#036B41", fontWeight: '600', fontSize: '37px', position:'relative', marginBottom: 0 }}>{currency=="USDT"?report.usdt:report.euro} 
                    <select onChange={(e) => setcurrency(e.target.value)} className="form-select float-end select-custom">
                      <option value="EURO" style={{ fontSize: '15px'}}>EURO</option>
                      <option value="USDT" style={{ fontSize: '15px'}}>USDT</option>                      
                    </select>
                    <i className='mdi mdi-chevron-down down-arrow'></i>
                    </h3>
                  </div>
                </Col>
                <Col sm="6" xs='12'>
                  <div>
                    <div>
                      <ReactApexChart
                        options={report.options}
                        series={currency=="USDT"?report.usdt_series:report.euro_series}
                        type="area"
                        height={70}
                        className="apex-charts"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      ))}
    </React.Fragment>
  )
}

export default USDT

USDT.propTypes = {
  reports: PropTypes.array
}