import React from "react";
import { Redirect } from "react-router-dom";


// Profile
import UserProfile from "../pages/Authentication/user-profile";
import PaymentPreference from "../pages/PaymentPreference/index";
import Dividends from "../pages/Dividends/index";

// Authentication
import Login from "../pages/Authentication/Login";
import Googleauth from "../pages/Authentication/googleauth";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgotPassword";
import ChangePwd from "../pages/Authentication/ChangePassword";
import MobileVerification from "../pages/Authentication/MobileVerification";
import KycDetails from "../pages/Authentication/KycDetails";
import Changepassword from "../pages/ChangePassword/ChangePassword";
import Twofaauthenticator from "../pages/TWOFAAuthenticator/Twofaauthenticator";
import Properties from "../pages/Property/index";
import PropertyDetails from "../pages/Property/PropertyDetails";
import KYCSuccess from "../pages/Authentication/kyc-success";
import KYCDeclined from "../pages/Authentication/kyc-declined";
import KYCFailure from "../pages/Authentication/kyc-failure";
import PropertyDetailsStatus from "../pages/Authentication/PropertyDetailsStatus";
import RegisterByCollaborator from "../pages/Authentication/Register-By-Collaborator";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Collaborator
import Collaborator from "../pages/Collaborator/index";
import CollaboratorUsersTransaction from "../pages/Collaborator/usersTransaction";

//KYC
import Kyc from "../pages/Kyc/kyc";
import Kyb from "../pages/Kyb/kyb";
import MyTransaction from "../pages/MyTransaction/MyTransaction";
import Portfolio from "../pages/Portfolio/Portfolio";
import MyWallet from "../pages/MyWallet/wallet";
import MyWithdrawal from "../pages/MyWallet/mywithdrawal";
import ClaimedFund from "../pages/ClaimedFund/index";

import Pages404 from "../pages/Utility/pages-404";


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/collaborator", component: Collaborator },
  { path: "/collaboratoruserstransaction/:id", component: CollaboratorUsersTransaction },
  { path: "/profile", component: UserProfile },
  { path: "/payment-preference", component: PaymentPreference },
  { path: "/dividends", component: Dividends },
  { path: "/twofaauthenticator", component: Twofaauthenticator },
  { path: "/kyc", component: Kyc },
  { path: "/kyb", component: Kyb },
  { path: "/changepassword", component: Changepassword },
  { path: "/properties", component: Properties },
  { path: "/property-details", component: PropertyDetails },
  { path: "/property-details/:id", component: PropertyDetails },
  { path: "/mytransaction", component: MyTransaction },
  { path: "/portfolio", component: Portfolio },
  { path: "/wallet", component: MyWallet },
  { path: "/withdrawal", component: MyWithdrawal },
  { path: "/claimed-fund", component: ClaimedFund },


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/google-auth", component: Googleauth },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/change-password", component: ChangePwd },
  { path: "/register", component: Register },
  { path: "/register/:referral_code", component: Register },
  { path: "/register/collaborator/:collaborator_referral_code", component: RegisterByCollaborator },
  { path: "/email-mobile-verification", component: MobileVerification },
  { path: "/kyc-details", component: KycDetails },
  { path: "/kyc-success", component: KYCSuccess },
  { path: "/kyc-declined", component: KYCDeclined },
  { path: "/kyc-failure", component: KYCFailure },
  { path: "/property-details-status/:status", component: PropertyDetailsStatus },

  
  // // Wildcard route for unmatched routes
  // { path: "*", component: Pages404 },
];

export { authProtectedRoutes, publicRoutes };
